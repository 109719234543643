import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Header from '../../common/Header';

const mapStateToProps = ({common}) => {
	return ({
		isEmbed: common.isEmbed,
		embedMoverId: common.embedMoverId,
		embedMoverSchemeColor: common.embedMoverSchemeColor

	})
}

const NoMoverModal = props => {
	const [modal, setModal] = useState(true);
	const back = () => {
		setModal(!modal);
		props.isEmbed ?
			props.push(`/${props.embedMoverId}/address?schemeColor=${props.embedMoverSchemeColor}`)		
		:
			props.push('/');
	};
	return (
		<div>
			<Header changeTheme={true} />
			<Modal isOpen={modal} className="no-mover-modal">
				<ModalHeader toggle={back}>No Movers</ModalHeader>
				<ModalBody>No movers found in your area</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={back}>
						Back to Home
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default connect(mapStateToProps, { push })(NoMoverModal);
