import {
	SET_USER_GEOADDRESS,
	SET_PICK_UP,
	SET_DROP_OFF,
	SET_EXTRA_DROP_OFF,
	GET_ALL_MOVEPLANS,
	GET_MOVEPLAN,
	SET_CURRENT_MOVEPLAN_ID,
	GET_CURRENT_MOVEPLAN,
	SET_CURRENT_PLAN_INFO,
	ADD_BOX_PURCHASE,
	REMOVE_BOX_PURCHASES,
	REMOVE_BOX_PURCHASE,
	SET_MOVE_DATE,
	GET_VALID_DATES,
	UPDATE_MOVE_DATE,
	SET_VALID_DATES,
	UPDATE_MOVE_PLAN,
	UPDATE_BOX_PURCHASES,
	UPDATE_PACKING_SERVICES,
	SET_COI_INFO,
	SET_TIME_WINDOW,
	CHECK_VALID_DATES,
	CHECK_MOVER_AVAILABILITY,
	PREPARE_RESCHEDULE_REQUEST,
	SET_RESCHEDULE_REQUEST,
	EMAIL_PLAN,
	ADDRESS_UPDATE,
	REQUEST_INSURANCE,
	SET_MOVEPLAN_INSURANCE,
	CANCEL_MOVE,
	INIT_BOX_PURCHASES,
	CLEAR_BOX_PURCHASES,
	CREATE_MOVEPLAN,
	CREATE_MOVEPLAN_FOR_PARTNER,
	SUBMIT_DNA,
	SUBMIT_DNA_FOREMBEDS,
	SET_MOVEPLAN_LOADING,
	CONTINUE_LAST_PLAN,
	SET_DNA_ERROR,
	SUBMIT_UNSERVED_EMAIL,
	UPDATE_BOX_PURCHASE_COUNT,
	UPDATE_MOVE_TIME,
	SUBMIT_RESCHEDULE_REQUEST,
	SET_BOX_DELIVERY_DATE,
	INIT_REVIEW_PAGE,
	GET_DIRECTION,
	UPDATE_HEIGHT,
	SET_UPDATE_HEIGHT_LOADING,
	SUBMIT_LANDING_PICK_UP,
	CREATE_MOVEPLAN_FOREMBEDS,
} from '../actionTypes';

export const submitDNA = payload => ({
	type: SUBMIT_DNA,
	payload: payload,
});

export const submitDNAforEmbeds = payload => ({
	type: SUBMIT_DNA_FOREMBEDS,
	payload: payload
})

export const createMovePlan = params => ({
	type: CREATE_MOVEPLAN,
	payload: params,
});

export const createMovePlanForEmbeds = params => ({
	type: CREATE_MOVEPLAN_FOREMBEDS,
	payload: params
})

export const createForPartner = params => ({
	type: CREATE_MOVEPLAN_FOR_PARTNER,
	payload: params,
});

export const updateMovePlan = (newParams, meta) => ({
	type: UPDATE_MOVE_PLAN,
	payload: newParams,
	meta: meta,
});

export const setCurrentMoveplanID = movePlanId => ({
	type: SET_CURRENT_MOVEPLAN_ID,
	payload: { movePlanId },
});

export const getCurrentMoveplan = meta => ({
	type: GET_CURRENT_MOVEPLAN,
	meta: !!meta ? meta : {},
});

export const setCurrentPlanInfo = plan => ({
	type: SET_CURRENT_PLAN_INFO,
	payload: { plan },
});

export const getAllMoveplans = redirect => ({
	type: GET_ALL_MOVEPLANS,
	meta: !!redirect ? { redirect: redirect } : {},
});

export const getMoveplan = uuid => ({
	type: GET_MOVEPLAN,
	payload: uuid,
});

export const addBoxPurchase = boxId => ({
	type: ADD_BOX_PURCHASE,
	payload: boxId,
});

export const removeBoxPurchase = boxId => ({
	type: REMOVE_BOX_PURCHASE,
	payload: boxId,
});

export const removeBoxPurchases = () => ({
	type: REMOVE_BOX_PURCHASES,
});

export const updateBoxPurchaseCount = (boxId, count) => ({
	type: UPDATE_BOX_PURCHASE_COUNT,
	payload: { boxId: boxId, count: count },
});

export const updateMoveDate = move_date => ({
	type: UPDATE_MOVE_DATE,
	payload: { move_date },
});

export const updateMoveTime = move_time => ({
	type: UPDATE_MOVE_TIME,
	payload: { move_time },
});

export const setMoveDate = moveDate => ({
	type: SET_MOVE_DATE,
	payload: moveDate,
});

export const getValidDates = () => ({
	type: GET_VALID_DATES,
});

export const setValidDates = validDates => ({
	type: SET_VALID_DATES,
	payload: validDates,
});

export const updateBoxPurchases = (boxPurchases, date) => ({
	type: UPDATE_BOX_PURCHASES,
	payload: { date: date, boxPurchases: boxPurchases },
});

export const updatePackingServices = serviceNumber => ({
	type: UPDATE_PACKING_SERVICES,
	payload: serviceNumber,
});

export const setCoiInfo = coiInfo => ({
	type: SET_COI_INFO,
	payload: coiInfo,
});

export const setTimeWindow = timeWindow => ({
	type: SET_TIME_WINDOW,
	payload: timeWindow,
});

export const checkValidDates = params => ({
	type: CHECK_VALID_DATES,
	payload: params,
});

export const checkMoverAvailability = params => ({
	type: CHECK_MOVER_AVAILABILITY,
	payload: params,
});

export const prepareRescheduleRequest = params => ({
	type: PREPARE_RESCHEDULE_REQUEST,
	payload: params,
});

export const setRescheduleRequest = params => ({
	type: SET_RESCHEDULE_REQUEST,
	payload: params,
});

export const emailPlan = email => ({
	type: EMAIL_PLAN,
	payload: email,
});

export const addressUpdate = (addressKey, route) => ({
	type: ADDRESS_UPDATE,
	payload: { addressKey: addressKey, route: route },
});

export const requestInsurance = () => ({
	type: REQUEST_INSURANCE,
});

export const setMoveplanInsurance = bool => ({
	type: SET_MOVEPLAN_INSURANCE,
	payload: bool,
});

export const cancelMove = () => ({
	type: CANCEL_MOVE,
});

export const initBoxPurchases = inventoryBoxes => ({
	type: INIT_BOX_PURCHASES,
	payload: inventoryBoxes,
});

export const clearBoxPurchases = () => ({
	type: CLEAR_BOX_PURCHASES,
});

export const setMoveplanLoading = bool => ({
	type: SET_MOVEPLAN_LOADING,
	payload: bool,
});

export const continueLastPlan = () => ({
	type: CONTINUE_LAST_PLAN,
});

export const setDnaError = error => ({
	type: SET_DNA_ERROR,
	payload: error,
});

export const submitUnservedEmail = email => ({
	type: SUBMIT_UNSERVED_EMAIL,
	payload: email,
});

export const submitRescheduleRequest = params => ({
	type: SUBMIT_RESCHEDULE_REQUEST,
	payload: URLSearchParams,
});

export const setBoxDeliveryDate = date => ({
	type: SET_BOX_DELIVERY_DATE,
	payload: date,
});

export const initReviewPage = movePlanId => ({
	type: INIT_REVIEW_PAGE,
	payload: movePlanId,
});

export const setUserGeoAddress = userCoordinates => ({
	type: SET_USER_GEOADDRESS,
	payload: userCoordinates,
});

export const setDropOff = address => ({
	type: SET_DROP_OFF,
	payload: address,
});

export const setExtraDropOff = address => ({
	type: SET_EXTRA_DROP_OFF,
	payload: address,
});

export const setPickUp = address => ({
	type: SET_PICK_UP,
	payload: address,
});

export const getDirection = () => ({
	type: GET_DIRECTION,
});

export const updateHeight = payload => ({
	type: UPDATE_HEIGHT,
	payload: payload,
});

export const setUpdateHeightLoading = bool => ({
	type: SET_UPDATE_HEIGHT_LOADING,
	payload: bool,
});

export const submitLandingPickUp = address => ({
	type: SUBMIT_LANDING_PICK_UP,
	payload: address,
});
