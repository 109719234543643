import {
	BOOK,
	CHECK_PROMO,
	INIT_BOOK_PAGE,
	SET_BOOK_CONTACT,
	SET_LOGIN_INFO,
	SET_CARD_ERRORS,
	SET_CLIENT_TOKEN,
	SET_HOSTED_FIELDS_INSTANCE,
	SET_USER,
	CALCULATE_BOOK,
	CALCULATE_BOOK_FOREMBEDS,
	SUBMIT_BOOK,
	TOGGLE_IS_SUBMITTING,
	SET_BOOK_NONCE,
	SET_PROMO_LOADING,
	SET_PAYPAL_COMPLETE,
	SET_PAYPAL_EMAIL,
	CANCEL_PAYPAL,
	SET_BOOK_LOADING,
	SET_LOGIN_FAILURE,
} from '../actionTypes';

export const initBookPage = movePlanId => ({
	type: INIT_BOOK_PAGE,
	payload: movePlanId,
});

export const checkPromo = couponCode => ({
	type: CHECK_PROMO,
	payload: couponCode,
});

export const setClientToken = token => ({
	type: SET_CLIENT_TOKEN,
	payload: token,
});

export const setCardErrors = errors => ({
	type: SET_CARD_ERRORS,
	payload: errors,
});

export const setUser = user => ({
	type: SET_USER,
	payload: user,
});

export const setHostedFieldsInstance = instance => ({
	type: SET_HOSTED_FIELDS_INSTANCE,
	payload: instance,
});

export const book = payload => ({
	type: BOOK,
	payload: payload,
});

export const calculateBook = payload => ({
	type: CALCULATE_BOOK,
});

export const calculateBookForEmbeds = payload => ({
	type: CALCULATE_BOOK_FOREMBEDS
})

export const submitBook = payload => ({
	type: SUBMIT_BOOK,
	payload: payload,
});

export const setBookContact = payload => ({
	type: SET_BOOK_CONTACT,
	payload: payload,
});

export const setLoginInfo = payload => ({
	type: SET_LOGIN_INFO,
	payload: payload,
});

export const toggleIsSubmitting = () => ({
	type: TOGGLE_IS_SUBMITTING,
});

export const setBookNonce = nonce => ({
	type: SET_BOOK_NONCE,
	payload: nonce,
});

export const setPromoLoading = bool => ({
	type: SET_PROMO_LOADING,
	payload: bool,
});

export const setPaypalComplete = bool => ({
	type: SET_PAYPAL_COMPLETE,
	payload: bool,
});

export const setPaypalEmail = email => ({
	type: SET_PAYPAL_EMAIL,
	payload: email,
});

export const cancelPaypal = () => ({
	type: CANCEL_PAYPAL,
});

export const setBookLoading = bool => ({
	type: SET_BOOK_LOADING,
	payload: bool,
});

export const setLoginFailure = bool => ({
	type: SET_LOGIN_FAILURE,
	payload: bool,
});
