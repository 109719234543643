import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../common/PageNotFound';
import { useMedia } from '../start/landing/customHooks';
import SignIn from '../start/auth/SignIn';
import ForgotPassword from '../start/auth/ForgotPassword';
import Register from '../start/auth/Register';
import { checkCookies } from '../../redux/actions/auth';
import { isMoveNowEmbed } from '../../redux/actions/common'
import MovingContainer from '../moving/MovingContainer';
import Email from '../start/Email';
import Legal from '../common/Legal';
import Landing from '../start/landing/Landing';
import LandingDna from '../start/landing/LandingDna';
import MyHomeContainer from '../myhome/MyHomeContainer';
import About from '../marketing/About';
import HowItWorks from '../marketing/HowItWorks';
import CustomerService from '../marketing/CustomerService';
import NoMoverModal from '../moving/review/NoMoverModal';

const mapStateToProps = ({ movePlan, common }) => {
	return ({
		mpid: movePlan.currentMPID,
		headerMargin: common.headerMargin,
	})
};

const FullWidthLayout = props => {
	//eslint-disable-next-line
	useEffect(() => props.checkCookies(document.cookie), []);

	const viewModel = useMedia(
		[
			'(min-width: 1440px)',
			'(min-width: 993px)',
			'(min-width: 768px)',
			'(min-width: 1px)',
		],
		['wide', 'desktop', 'tablet', 'mobile'],
		'desktop'
	);

	return (
		<div className={`${props.headerMargin && 'mobile-app-container'}`}>
			<Switch>
				<Route
					exact
					path="/"
					render={() => <Landing viewModel={viewModel} />}
				/>
				<Route
					exact
					path="/address"
					render={() => <LandingDna viewModel={viewModel} />}
				/>
				<Route
				exact
				path="/:moverId/address"
				render={() => <LandingDna viewModel={viewModel} />}
				/>
				<Route exact path="/sign-in" component={SignIn} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/about" component={About} />
				<Route exact path="/how-it-works" component={HowItWorks} />
				<Route exact path="/customer-service" component={CustomerService} />
				<Route exact path="/forgot-password" component={ForgotPassword} />
				<Route exact path="/email" component={Email} />
				<Route exact path="/legal" component={Legal} />
				<Route path="/moving/:mpid/" component={MovingContainer} />
				<Route path="/my-home/" component={MyHomeContainer} />
				<Route path="/no-movers/" component={NoMoverModal} />
				<Route component={PageNotFound} />
			</Switch>
		</div>
	);
};

export default connect(mapStateToProps, { checkCookies, isMoveNowEmbed })(FullWidthLayout);
