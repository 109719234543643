// API
export const API_REQUEST = 'API_REQUEST';
export const NETWORK_PENDING = 'NETWORK_PENDING';
export const NETWORK_SUCCESS = 'NETWORK_SUCCESS';
export const NETWORK_FAILURE = 'NETWORK_FAILURE';

// UI / Mobile
export const SHOW_LEFT_MENU_TOGGLE = 'SHOW_LEFT_MENU_TOGGLE';
export const HIDE_LEFT_MENU_TOGGLE = 'HIDE_LEFT_MENU_TOGGLE';
export const SHOW_RIGHT_MENU_TOGGLE = 'SHOW_RIGHT_MENU_TOGGLE';
export const HIDE_RIGHT_MENU_TOGGLE = 'HIDE_RIGHT_MENU_TOGGLE';
export const TOGGLE_LEFT_MENU = 'TOGGLE_LEFT_MENU';
export const TOGGLE_RIGHT_MENU = 'TOGGLE_RIGHT_MENU';
export const TOGGLE_STEPS_MENU = 'TOGGLE_STEPS_MENU';
export const HIDE_ALL_MENUS = 'HIDE_ALL_MENUS';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const CHECK_IS_MOBILE = 'CHECK_IS_MOBILE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

// Inventory
export const INIT_INVENTORY_PAGE = 'INIT_INVENTORY_PAGE';
export const CREATE_NEW_PLAN = 'CREATE_NEW_PLAN';
export const INVENTORY_SEARCH_FIELD_FOCUS = 'INVENTORY_SEARCH_FIELD_FOCUS';
export const INVENTORY_SEARCH_FIELD_BLUR = 'INVENTORY_SEARCH_FIELD_BLUR';
export const UPDATE_INVENTORY_SEARCH_QUERY = 'UPDATE_INVENTORY_SEARCH_QUERY';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const FETCH_ALL_INVENTORY = 'FETCH_ALL_INVENTORY';
export const SET_ALL_INVENTORY = 'SET_ALL_INVENTORY';
export const UPDATE_INVENTORY_ROOMS = 'UPDATE_INVENTORY_ROOMS';
export const UPDATE_INVENTORY_ITEMS = 'UPDATE_INVENTORY_ITEMS';
export const UPDATE_INVENTORY_BOXES = 'UPDATE_INVENTORY_BOXES';
export const UPDATE_ROOM_COUNTS = 'UPDATE_ROOM_COUNTS';
export const UPDATE_CUSTOM_ITEMS = 'UPDATE_CUSTOM_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_ITEM_COUNT = 'UPDATE_ITEM_COUNT';
export const ADD_BOX = 'ADD_BOX';
export const REMOVE_BOX = 'REMOVE_BOX';
export const UPDATE_BOX_COUNT = 'UPDATE_BOX_COUNT';
export const ADD_TYPICALS = 'ADD_TYPICALS';
export const ADD_TYPICAL_ITEMS = 'ADD_TYPICAL_ITEMS';
export const ADD_TYPICAL_BOXES = 'ADD_TYPICAL_BOXES';
export const REMOVE_TYPICALS = 'REMOVE_TYPICALS';
export const REMOVE_TYPICAL_ITEMS = 'REMOVE_TYPICAL_ITEMS';
export const REMOVE_TYPICAL_BOXES = 'REMOVE_TYPICAL_BOXES';
export const SET_HAS_TYPICALS = 'SET_HAS_TYPICALS';
export const TOGGLE_HAS_TYPICAL_BOXES = 'TOGGLE_HAS_TYPICAL_BOXES';
export const SET_INVENTORY_VIEW = 'SET_INVENTORY_VIEW';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const SET_LAST_INVENTORY_ACTION = 'SET_LAST_INVENTORY_ACTION';
export const UPDATE_CUBIC_FEET = 'UPDATE_CUBIC_FEET';
export const CREATE_CUSTOM_ITEM = 'CREATE_CUSTOM_ITEM';
export const ADD_CUSTOM_ITEM = 'ADD_CUSTOM_ITEM';
export const REMOVE_CUSTOM_ITEM = 'REMOVE_CUSTOM_ITEM';
export const INIT_SPECIAL_HANDLING_ITEMS = 'INIT_SPECIAL_HANDLING_ITEMS';
export const TOGGLE_SPECIAL_HANDLING_ASSEMBLY =
	'TOGGLE_SPECIAL_HANDLING_ASSEMBLY';
export const TOGGLE_SPECIAL_HANDLING_CRATING =
	'TOGGLE_SPECIAL_HANDLING_CRATING';
export const TOGGLE_SPECIAL_HANDLING_DISMOUNTING =
	'TOGGLE_SPECIAL_HANDLING_DISMOUNTING';
export const UPDATE_SPECIAL_HANDLING = 'UPDATE_SPECIAL_HANDLING';
export const ADD_SPECIAL_HANDLING_ITEMS = 'ADD_SPECIAL_HANDLING_ITEMS';
export const ADD_INVENTORY_PRICING = 'ADD_INVENTORY_PRICING';
export const SET_INVENTORY_PRICING = 'SET_INVENTORY_PRICING';
export const SET_INVENTORY_LOADING = 'SET_INVENTORY_LOADING';
export const SET_ADDED_TYPICALS = 'SET_ADDED_TYPICALS';
export const SET_SHOW_NO_BOXES_POP = 'SET_SHOW_NO_BOXES_POP';
export const SET_SUGGESTED_BOXES = 'SET_SUGGESTED_BOXES';
export const SET_IS_MODIFIED = 'SET_IS_MODIFIED';
export const INIT_INVENTORY_ROOMS = 'INIT_INVENTORY_ROOMS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

// Funnel
export const NEXT_STEP = 'NEXT_STEP';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_LAST_STEP = 'SET_LAST_STEP';
export const GOTO_MY_INVENTORY = 'GOTO_MY_INVENTORY';
export const GOTO_DETAILS = 'GOTO_DETAILS';
export const GOTO_BOXES = 'GOTO_BOXES';
export const GOTO_STEP = 'GOTO_STEP';
export const GOTO_COMPARE = 'GOTO_COMPARE';
export const GOTO_REVIEW = 'GOTO_REVIEW';
export const GOTO_PLAN = 'GOTO_PLAN';
export const GOTO_BOOK = 'GOTO_BOOK';
export const GOTO_LAST_STEP = 'GOTO_LAST_STEP';
export const PRINT_PAGE = 'PRINT_PAGE';
export const SET_STEP_COMPLETED = 'SET_STEP_COMPLETED';
export const GOTO_CONGRATS = 'GOTO_CONGRATS';
export const RESET_FUNNEL = 'RESET_FUNNEL';
export const IMPORT_FUNNEL_STATE = 'IMPORT_FUNNEL_STATE';
export const OPEN_OLARK = 'OPEN_OLARK';
export const SET_STEP = 'SET_STEP';

// Auth
export const SET_ROLE = 'SET_ROLE';
export const CHECK_EMAIL_AVAILABILITY = 'CHECK_EMAIL_AVAILABILITY';
export const SET_EMAIL_TAKEN = 'SET_EMAIL_TAKEN';
export const REGISTER_USER = 'REGISTER_USER';
export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CHECK_COOKIES = 'CHECK_COOKIES';
export const SET_ERROR_MSG = 'SET_ERROR_MSG';
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
export const UPDATE_FACEBOOK_AUTH = 'UPDATE_FACEBOOK_AUTH';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const SET_AUTH_MODAL_VIEW = 'SET_AUTH_MODAL_VIEW';
export const SET_AUTH_MODAL_ERROR = 'SET_AUTH_MODAL_ERROR';
export const SET_AUTH_SUBMITTING = 'SET_AUTH_SUBMITTING';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_RESET_EMAIL = 'SET_RESET_EMAIL';
export const SET_LAST_MPID = 'SET_LAST_MPID';
export const SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT';
export const SET_ALL_MOVEPLANS = 'SET_ALL_MOVEPLANS';

// Moveplan
export const GET_MOVEPLAN = 'GET_MOVEPLAN';
export const GET_ALL_MOVEPLANS = 'GET_ALL_MOVEPLANS';
export const SET_MOVEPLAN_LOADING = 'SET_MOVEPLAN_LOADING';
export const CREATE_MOVEPLAN = 'CREATE_MOVEPLAN';
export const CREATE_MOVEPLAN_FOREMBEDS = 'CREATE_MOVEPLAN_FOREMBEDS'
export const ADD_TYPICAL_INVENTORY_FOR_EMBEDS = 'ADD_TYPICAL_INVENTORY_FOR_EMBEDS'
export const ADD_TYPICAL_BOXES_FOR_EMBEDS = 'ADD_TYPICAL_BOXES_FOR_EMBEDS'
export const CREATE_MOVEPLAN_FOR_PARTNER = 'CREATE_MOVEPLAN_FOR_PARTNER';
export const UPDATE_MOVE_PLAN = 'UPDATE_MOVE_PLAN';
export const SET_CURRENT_MOVEPLAN_ID = 'SET_CURRENT_MOVEPLAN';
export const GET_CURRENT_MOVEPLAN = 'GET_CURRENT_MOVEPLAN';
export const SET_CURRENT_PLAN_INFO = 'SET_CURRENT_PLAN_INFO';
export const SET_BOX_PURCHASES = 'SET_BOX_PURCHASES';
export const ADD_BOX_PURCHASE = 'ADD_BOX_PURCHASE';
export const REMOVE_BOX_PURCHASE = 'REMOVE_BOX_PURCHASE';
export const REMOVE_BOX_PURCHASES = 'REMOVE_BOX_PURCHASES';
export const SET_MOVE_DATE = 'SET_MOVE_DATE';
export const UPDATE_MOVE_DATE = 'UPDATE_MOVE_DATE';
export const GET_VALID_DATES = 'GET_VALID_DATES';
export const SET_VALID_DATES = 'SET_VALID_DATES';
export const UPDATE_BOX_PURCHASES = 'UPDATE_BOX_PURCHASES';
export const UPDATE_PACKING_SERVICES = 'UPDATE_PACKING_SERVICES';
export const SET_COI_INFO = 'SET_COI_INFO';
export const SET_TIME_WINDOW = 'SET_TIME_WINDOW';
export const CHECK_VALID_DATES = 'CHECK_VALID_DATES';
export const CHECK_MOVER_AVAILABILITY = 'CHECK_MOVER_AVAILABILITY';
export const SET_RESCHEDULE_REQUEST = 'SET_RESCHEDULE_REQUEST';
export const PREPARE_RESCHEDULE_REQUEST = 'PREPARE_RESCHEDULE_REQUEST';
export const EMAIL_PLAN = 'EMAIL_PLAN';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const REQUEST_INSURANCE = 'REQUEST_INSURANCE';
export const SET_MOVEPLAN_INSURANCE = 'SET_MOVEPLAN_INSURANCE';
export const CANCEL_MOVE = 'CANCEL_MOVE';
export const INIT_BOX_PURCHASES = 'INIT_BOX_PURCHASES';
export const CLEAR_BOX_PURCHASES = 'CLEAR_BOX_PURCHASES';
export const SET_BOX_DELIVERY_DATE = 'SET_BOX_DELIVERY_DATE';
export const CONTINUE_LAST_PLAN = 'CONTINUE_LAST_PLAN';
export const SET_DNA_ERROR = 'SET_DNA_ERROR';
export const SUBMIT_DNA = 'SUBMIT_DNA';
export const SUBMIT_DNA_FOREMBEDS = 'SUBMIT_DNA_FOREMBEDS'
export const SUBMIT_RESCHEDULE_REQUEST = 'SUBMIT_RESCHEDULE_REQUEST';
export const SUBMIT_UNSERVED_EMAIL = 'SUBMIT_UNSERVED_EMAIL';
export const UPDATE_BOX_PURCHASE_COUNT = 'UPDATE_BOX_PURCHASE_COUNT';
export const UPDATE_MOVE_TIME = 'UPDATE_MOVE_TIME';
export const INIT_REVIEW_PAGE = 'INIT_REVIEW_PAGE';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_LAST_MODAL = 'CLOSE_LAST_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

// Compare
export const INIT_COMPARE_PAGE = 'INIT_COMPARE_PAGE';
export const SET_COMPARE_FILTER = 'SET_COMPARE_FILTER';
export const GET_BIDS = 'GET_BIDS';
export const SET_BIDS = 'SET_BIDS';
export const ADD_DELIVERY_ESTIMATE_OPTION = 'ADD_DELIVERY_ESTIMATE_OPTION';
export const SET_FILTERED_RESULTS = 'SET_FILTERED_RESULTS';
export const FILTER_COMPARE_RESULTS = 'FILTER_COMPARE_RESULTS';
export const SELECT_MOVER = 'SELECT_MOVER';
export const GET_PRICING_BREAKDOWN = 'GET_PRICING_BREAKDOWN';
export const SET_PRICING_BREAKDOWN = 'SET_PRICING_BREAKDOWN';
export const SET_COMPARE_LOADING = 'SET_COMPARE_LOADING';

// Consultations
export const TOGGLE_CONSULTATIONS_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const SET_CONSULTATIONS_MOVER = 'SET_CONSULTATIONS_MOVER';
export const SET_CONSULTATION_DATE = 'SET_CONSULTATION_DATE';
export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
export const TOGGLE_SUBMITTING = 'TOGGLE_SUBMITTING';
export const SET_CONSULTATION_TIME = 'SET_CONSULTATION_TIME';
export const GET_POTENTIAL_MOVERS = 'GET_POTENTIAL_MOVERS';
export const SET_POTENTIAL_MOVERS = 'SET_POTENTIAL_MOVERS';
export const SELECT_CONSULTATION_MOVER = 'SELECT_CONSULTATION_MOVER';
export const SET_NO_MOVERS_FOUND = 'SET_NO_MOVERS_FOUND';
export const SEND_CONSULTATION_REQUEST = 'SEND_CONSULTATION_REQUEST';
export const SET_CONSULTATIONS_TYPE = 'SET_CONSULTATIONS_TYPE';
export const FETCH_ONSITE_REQUESTS = 'FETCH_ONSITE_REQUESTS';
export const SET_ONSITE_REQUESTS = 'SET_ONSITE_REQUESTS';

// Book
export const INIT_BOOK_PAGE = 'INIT_BOOK_PAGE';
export const CHECK_PROMO = 'CHECK_PROMO';
export const SET_COUPON_DATA = 'SET_COUPON_DATA';
export const SET_INVALID_COUPON = 'SET_INVALID_COUPON';
export const SET_CLIENT_TOKEN = 'SET_CLIENT_TOKEN';
export const SET_CARD_ERRORS = 'SET_CARD_ERRORS';
export const SET_HOSTED_FIELDS_INSTANCE = 'SET_HOSTED_FIELDS_INSTANCE';
export const BOOK = 'BOOK';
export const SET_PROMO_LOADING = 'SET_PROMO_LOADING';
export const CALCULATE_BOOK = 'CALCULATE_BOOK';
export const CALCULATE_BOOK_FOREMBEDS = 'CALCULATE_BOOK_FOREMBEDS'
export const SUBMIT_BOOK = 'SUBMIT_BOOK';
export const SET_BOOK_CONTACT = 'SET_BOOK_CONTACT';
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';
export const TOGGLE_IS_SUBMITTING = 'TOGGLE_IS_SUBMITTING';
export const SET_BOOK_LOADING = 'SET_BOOK_LOADING';
export const SET_LOGIN_FAILURE = 'SET_LOGIN_FAILURE';
export const SET_BOOK_NONCE = 'SET_BOOK_NONCE';
export const CANCEL_PAYPAL = 'CANCEL_PAYPAL';
export const SET_PAYPAL_EMAIL = 'SET_PAYPAL_EMAIL';
export const SET_PAYPAL_COMPLETE = 'SET_PAYPAL_COMPLETE';

//Details
export const INIT_DETAILS_PAGE = 'INIT_DETAILS_PAGE';
export const SUBMIT_LOCATION = 'SUBMIT_LOCATION';
export const SET_DETAILS_LOADING = 'SET_DETAILS_LOADING';

//Plan
export const INIT_PLAN = 'INIT_PLAN';
export const INIT_ROUTES = 'INIT_ROUTES';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_NOTIFICATION_DISMISSED = 'SET_NOTIFICATION_DISMISSED';
export const SET_SELECTED_TAB_PLAN = 'SET_SELECTED_TAB_PLAN';
export const FINALIZE_PLAN = 'FINALIZE_PLAN';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';
export const SET_EMAIL_SENT = 'SET_EMAIL_SENT';
export const INIT_PLAN_FINISHED = 'INIT_PLAN_FINISHED';
export const TOGGLE_STORAGE_PLAN = 'TOGGLE_STORAGE_PLAN';
export const SET_SHOW_STORAGE = 'SET_SHOW_STORAGE';
export const SET_PLAN_UPDATING = 'SET_PLAN_UPDATING';
export const GET_HISTORY = 'GET_HISTORY';
export const SET_HISTORY_UPDATING = 'SET_HISTORY_UPDATING';
export const SET_HISTORY = 'SET_HISTORY';
export const GET_NOTES = 'GET_NOTES';
export const SET_NOTES_UPDATING = 'SET_NOTES_UPDATING';
export const SET_ADMIN_NOTES = 'SET_ADMIN_NOTES';
export const SET_MOVER_NOTES = 'SET_MOVER_NOTES';
export const ADD_NOTE = 'ADD_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_ADJUSTMENT = 'DELETE_ADJUSTMENT';
export const SET_ADJUSTMENT_LOADING = 'SET_ADJUSTMENT_LOADING';
export const UPDATE_ADJUSTMENT = 'UPDATE_ADJUSTMENT';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const APPLY_COUPON = 'APPLY_COUPON';
export const INIT_PRINT_PLAN = 'INIT_PRINT_PLAN';
export const UPDATE_HEIGHT = 'UPDATE_HEIGHT';
export const SET_UPDATE_HEIGHT_LOADING = 'SET_UPDATE_HEIGHT_LOADING';
export const MOVE_DAY_ADJUSTMENT = 'MOVE_DAY_ADJUSTMENT';

//Analytics
export const TRACK_TO_SEGMENT = 'TRACK_TO_SEGMENT';
export const PAGE_TO_SEGMENT = 'PAGE_TO_SEGMENT';
export const TRACK_LOADED_MOVEDAY_PAGE = 'TRACK_LOADED_MOVEDAY_PAGE';
export const GET_UTM_PARAMS = 'GET_UTM_PARAMS';
export const SET_UTM_PARAMS = 'SET_UTM_PARAMS';

//Mover
export const ACCEPT_JOB = 'ACCEPT_JOB';
export const DECLINE_JOB = 'DECLINE_JOB';
export const SET_JOB_DECLINE_INFO = 'SET_JOB_DECLINE_INFO';

//Congrats
export const INIT_CONGRATS_PAGE = 'INIT_CONGRATS_PAGE';

//Social
export const LOAD_TWITTER = 'LOAD_TWITTER';
export const LOAD_FACEBOOK = 'LOAD_FACEBOOK';
export const SHARE = 'SHARE';

//My Home
export const INIT_MYHOME = 'INIT_MYHOME';
export const EMAIL_CHECKLIST = 'EMAIL_CHECKLIST';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const SET_ALL_PLANS = 'SET_ALL_PLANS';
export const START_NEW_PLAN = 'START_NEW_PLAN';
export const PRINT_CHECKLIST = 'PRINT_CHECKLIST';
export const REUSE_PLAN = 'REUSE_PLAN';
export const VIEW_PLAN = 'VIEW_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';

// Common
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const SELECT_SUGGESTION = 'SELECT_SUGGESTION';
export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';
export const SET_SCRIPT_LOADING = 'SET_SCRIPT_LOADING';
export const MOVE_MAP_TOP = 'MOVE_MAP_TOP';
export const SET_INPUT_FOCUSING = 'SET_INPUT_FOCUSING';
export const SET_HOME_SIZE_SELECTED = 'SET_HOME_SIZE_SELECTED';
export const SET_TOGGLED_EXTRA_DROP_OFF = 'SET_TOGGLED_EXTRA_DROP_OFF';
export const SET_HEADER_MARGIN = 'SET_HEADER_MARGIN';
export const SET_RESCHEDULE_MODAL = 'SET_RESCHEDULE_MODAL';
export const SET_RESCHEDULE_LOADING = 'SET_RESCHEDULE_LOADING';
export const IS_MOVENOW_EMBED = 'IS_MOVENOW_EMBED';
export const SET_MOVENOW_ISEMBED = 'SET_MOVENOW_ISEMBED';
export const SET_MOVER_PARAMS =  'SET_MOVER_PARAMS';

// ****************************************************
// Movenow
export const SET_USER_GEOADDRESS = 'SET_USER_GEOADDRESS';
export const SET_DROP_OFF = 'SET_DROP_OFF';
export const SET_PICK_UP = 'SET_PICK_UP';
export const SET_EXTRA_DROP_OFF = 'SET_EXTRA_DROP_OFF';
export const GEOCODING_API_REQUEST = 'GEOCODING_';
export const GET_DIRECTION = 'GET_DIRECTION';
export const SUBMIT_LANDING_PICK_UP = 'SUBMIT_LANDING_PICK_UP';
