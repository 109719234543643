export const FUNNEL_STEPS = {
	INVENTORY: {
		id: 'inventory',
		title: 'Inventory',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'DETAILS',
	},
	DETAILS: {
		id: 'details',
		title: 'Details',
		step: 'DETAILS',
		nextStepButtonText: 'continue',
		showNextStepButton: false,
		isCompleted: false,
		nextStep: 'REVIEW',
	},
	REVIEW: {
		id: 'reivew',
		title: 'Review',
		step: 'REVIEW',
		nextStepButtonText: 'continue',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'BOOK',
	},
	BOOK: {
		id: 'book',
		title: 'Book Online',
		step: 'INVENTORY',
		nextStepButtonText: 'continue',
		showNextStepButton: true,
		isCompleted: false,
		nextStep: 'CONGRATS',
	},
};

export const TIME_FRAMES = [
	{
		time: '8-10 AM',
		full_time: '8:00 AM - 10:00 AM',
	},
	{
		time: '9-11 AM',
		full_time: '9:00 AM - 11:00 AM',
	},
	{
		time: '1-3 PM',
		full_time: '1:00 PM - 3:00 PM',
	},
	{
		time: '2-4 PM',
		full_time: '2:00 PM - 4:00 PM',
	},
];

export const FLOW_STEPS = ['INVENTORY', 'DETAILS', 'REVIEW', 'BOOK'];

export const STATES = [
	{ value: 'AL', label: 'AL', full: 'Alabama' },
	{ value: 'AK', label: 'AK', full: 'Alaska' },
	{ value: 'AZ', label: 'AZ', full: 'Arizona' },
	{ value: 'AR', label: 'AR', full: 'Arkansas' },
	{ value: 'CA', label: 'CA', full: 'California' },
	{ value: 'CO', label: 'CO', full: 'Colorado' },
	{ value: 'CT', label: 'CT', full: 'Connecticut' },
	{ value: 'DC', label: 'DC', full: 'District of Columbia' },
	{ value: 'DE', label: 'DE', full: 'Delaware' },
	{ value: 'FL', label: 'FL', full: 'Florida' },
	{ value: 'GA', label: 'GA', full: 'Georgia' },
	{ value: 'HI', label: 'HI', full: 'Hawaii' },
	{ value: 'ID', label: 'ID', full: 'Idaho' },
	{ value: 'IL', label: 'IL', full: 'Illinois' },
	{ value: 'IN', label: 'IN', full: 'Indiana' },
	{ value: 'IA', label: 'IA', full: 'Iowa' },
	{ value: 'KS', label: 'KS', full: 'Kansas' },
	{ value: 'KY', label: 'KY', full: 'Kentucky' },
	{ value: 'LA', label: 'LA', full: 'Louisiana' },
	{ value: 'ME', label: 'ME', full: 'Maine' },
	{ value: 'MD', label: 'MD', full: 'Maryland' },
	{ value: 'MA', label: 'MA', full: 'Massachusetts' },
	{ value: 'MI', label: 'MI', full: 'Michigan' },
	{ value: 'MN', label: 'MN', full: 'Minnesota' },
	{ value: 'MS', label: 'MS', full: 'Mississippi' },
	{ value: 'MO', label: 'MO', full: 'Missouri' },
	{ value: 'MT', label: 'MT', full: 'Montana' },
	{ value: 'NE', label: 'NE', full: 'Nebraska' },
	{ value: 'NV', label: 'NV', full: 'Nevada' },
	{ value: 'NH', label: 'NH', full: 'New Hampshire' },
	{ value: 'NJ', label: 'NJ', full: 'New Jersey' },
	{ value: 'NM', label: 'NM', full: 'New Mexico' },
	{ value: 'NY', label: 'NY', full: 'New York' },
	{ value: 'NC', label: 'NC', full: 'North Carolina' },
	{ value: 'ND', label: 'ND', full: 'North Dakota' },
	{ value: 'OH', label: 'OH', full: 'Ohio' },
	{ value: 'OK', label: 'OK', full: 'Oklahoma' },
	{ value: 'OR', label: 'OR', full: 'Oregon' },
	{ value: 'PA', label: 'PA', full: 'Pennsylvania' },
	{ value: 'RI', label: 'RI', full: 'Rhode Island' },
	{ value: 'SC', label: 'SC', full: 'South Carolina' },
	{ value: 'SD', label: 'SD', full: 'South Dakota' },
	{ value: 'TN', label: 'TN', full: 'Tennessee' },
	{ value: 'TX', label: 'TX', full: 'Texas' },
	{ value: 'UT', label: 'UT', full: 'Utah' },
	{ value: 'VT', label: 'VT', full: 'Vermont' },
	{ value: 'VA', label: 'VA', full: 'Virginia' },
	{ value: 'WA', label: 'WA', full: 'Washington' },
	{ value: 'WV', label: 'WV', full: 'West Virginia' },
	{ value: 'WI', label: 'WI', full: 'Wisconsin' },
	{ value: 'WY', label: 'WY', full: 'Wyoming' },
];

export const TIME_OPTIONS = [
	'8:00 AM - 9:00 AM',
	'9:00 AM - 10:00 AM',
	'10:00 AM - 11:00 AM',
	'11:00 AM - 12:00 PM',
	'12:00 PM - 1:00 PM',
	'1:00 PM - 2:00 PM',
	'2:00 PM - 3:00 PM',
	'3:00 PM - 4:00 PM',
	'4:00 PM - 5:00 PM',
	'5:00 PM - 6:00 PM',
	'6:00 PM - 7:00 PM',
];

export const INVENTORY_BOXES = [
	{
		id: 1,
		name: 'Small Box',
		description: 'For small and heavy items',
		size: '12"x12.5"x17"',
		cubic_feet: 1.5,
		icon: 'linen',
	},
	{
		id: 2,
		name: 'Medium Box',
		description: 'For general stuff',
		size: '16"x16"x18"',
		cubic_feet: 3.0,
		icon: 'medium',
	},
	{
		id: 3,
		name: 'Large Box',
		description: 'For large and light items',
		size: '20"x20"x18"',
		cubic_feet: 4.5,
		icon: 'large',
	},
	{
		id: 4,
		name: 'China/Dish Box',
		description: 'For fragile items',
		size: '18"x18"x28"',
		cubic_feet: 5.2,
		icon: 'china',
	},
	{
		id: 5,
		name: 'File Box',
		description: 'For folders and files',
		size: '12"x12"x24"',
		cubic_feet: 2.0,
		icon: 'letter',
	},
	{
		id: 6,
		name: 'Legal File Box',
		description: 'For folders',
		size: '12"x15"x24"',
		cubic_feet: 2.5,
		icon: 'legal',
	},
	{
		id: 7,
		name: 'Wardrobe Box',
		description: 'For hangers',
		size: '24"x23"x48"',
		cubic_feet: 15.5,
		icon: 'wardrobe',
	},
];

export const HEIGHTS = [
	{ value: '1', label: 'Elevator' },
	{ value: '2', label: 'No stairs - ground floor' },
	{ value: '3', label: 'Stairs - 2nd floor' },
	{ value: '4', label: 'Stairs - 3rd floor' },
	{ value: '5', label: 'Stairs - 4th floor' },
	{ value: '6', label: 'Stairs - 5th floor' },
	{ value: '7', label: 'Stairs - 6th floor' },
];
export const HEIGHTSReview = [
	{ value: '1', label: 'Elevator' },
	{ value: '2', label: 'No stairs' },
	{ value: '3', label: '2nd floor' },
	{ value: '4', label: '3rd floor' },
	{ value: '5', label: '4th floor' },
	{ value: '6', label: '5th floor' },
	{ value: '7', label: '6th floor' },
];

export const INVENTORY_ROOMS = [
	'Living Room',
	'Bedroom',
	'Dining Room',
	'Kitchen',
	'Kids Room',
	'Office',
	'Outdoor',
	'Other',
];

export const INVENTORY_GROUPS = [
	[
		{
			id: 27,
			name: 'Sofas and Couches',
			room_id: 1,
			icon_css_class: 'inventory_icons-Seater_Sofa_3',
		},
		{
			id: 28,
			name: 'Chairs',
			room_id: 1,
			icon_css_class: 'inventory_icons-Arm_Chair',
		},
		{
			id: 29,
			name: 'Tables',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Coffee_Table',
		},
		{
			id: 63,
			name: 'Glass Tables',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Coffee_Table',
		},
		{
			id: 30,
			name: 'TVs',
			room_id: 1,
			icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55',
		},
		{
			id: 37,
			name: 'Stereo',
			room_id: 1,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 31,
			name: 'Cabinets',
			room_id: 1,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 32,
			name: 'Lamps',
			room_id: 1,
			icon_css_class: 'inventory_icons-Table_Lamp',
		},
		{
			id: 33,
			name: 'Bookcases',
			room_id: 1,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
		{
			id: 34,
			name: 'Pianos',
			room_id: 1,
			icon_css_class: 'inventory_icons-Baby_Grand_Piano',
		},
		{
			id: 35,
			name: 'Mirrors',
			room_id: 1,
			icon_css_class: 'inventory_icons-Small_Mirror',
		},
		{
			id: 36,
			name: 'Paintings & Pictures',
			room_id: 1,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 38,
			name: 'Odds & Ends',
			room_id: 1,
			icon_css_class: 'inventory_icons-Fireplace_Equipment',
		},
	],
	[
		{
			id: 1,
			name: 'Beds',
			room_id: 2,
			icon_css_class: 'inventory_icons-Bed',
		},
		{
			id: 2,
			name: 'Mattress Only',
			room_id: 2,
			icon_css_class: 'inventory_icons-California_King_Mattress',
		},
		{
			id: 3,
			name: 'Cabinets',
			room_id: 2,
			icon_css_class: 'inventory_icons-Small_Armoire',
		},
		{
			id: 4,
			name: 'TVs',
			room_id: 2,
			icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55',
		},
		{
			id: 10,
			name: 'Stereo',
			room_id: 2,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 5,
			name: 'Lamps',
			room_id: 2,
			icon_css_class: 'inventory_icons-Table_Lamp',
		},
		{
			id: 6,
			name: 'Tables',
			room_id: 2,
			icon_css_class: 'inventory_icons-Night_Table',
		},
		{
			id: 7,
			name: 'Chairs',
			room_id: 2,
			icon_css_class: 'inventory_icons-Arm_Chair',
		},
		{
			id: 8,
			name: 'Mirrors',
			room_id: 2,
			icon_css_class: 'inventory_icons-Small_Mirror',
		},
		{
			id: 9,
			name: 'Bookcases',
			room_id: 2,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
		{
			id: 11,
			name: 'Paintings & Pictures',
			room_id: 2,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 12,
			name: 'Odds & Ends',
			room_id: 2,
			icon_css_class: 'inventory_icons-Clothes_Steamer',
		},
	],
	[
		{
			id: 13,
			name: 'Tables',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 62,
			name: 'Glass Tables',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 14,
			name: 'Chairs',
			room_id: 3,
			icon_css_class: 'inventory_icons-Dining_Chair',
		},
		{
			id: 15,
			name: 'Cabinets',
			room_id: 3,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 65,
			name: 'Paintings & Pictures',
			room_id: 3,
			icon_css_class: 'inventory_icons-Picture_with_Frame',
		},
		{
			id: 16,
			name: 'Odds & Ends',
			room_id: 3,
			icon_css_class: 'inventory_icons-Chandelier',
		},
	],
	[
		{
			id: 22,
			name: 'Tables',
			room_id: 4,
			icon_css_class: 'inventory_icons-Kitchen_Table',
		},
		{
			id: 23,
			name: 'Chairs',
			room_id: 4,
			icon_css_class: 'inventory_icons-Kitchen_Chair',
		},
		{
			id: 24,
			name: 'Cabinets',
			room_id: 4,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 25,
			name: 'Appliances',
			room_id: 4,
			icon_css_class: 'inventory_icons-Coffee_maker',
		},
		{
			id: 26,
			name: 'Odds & Ends',
			room_id: 4,
			icon_css_class: 'inventory_icons-Water_Cooler',
		},
	],
	[
		{
			id: 17,
			name: 'Beds',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Bed',
		},
		{
			id: 18,
			name: 'Tables',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Table',
		},
		{
			id: 19,
			name: 'Chairs',
			room_id: 5,
			icon_css_class: 'inventory_icons-Childrens_Chair',
		},
		{
			id: 20,
			name: 'Toys',
			room_id: 5,
			icon_css_class: 'inventory_icons-Toy_Car',
		},
		{
			id: 21,
			name: 'Odds & Ends',
			room_id: 5,
			icon_css_class: 'inventory_icons-Stroller',
		},
	],
	[
		{
			id: 39,
			name: 'Tables & Desks',
			room_id: 6,
			icon_css_class: 'inventory_icons-Small_Desk',
		},
		{
			id: 40,
			name: 'Chairs',
			room_id: 6,
			icon_css_class: 'inventory_icons-Office-Chair',
		},
		{
			id: 41,
			name: 'Computers',
			room_id: 6,
			icon_css_class: 'inventory_icons-Computer',
		},
		{
			id: 42,
			name: 'Monitors',
			room_id: 6,
			icon_css_class: 'inventory_icons-Monitor',
		},
		{
			id: 43,
			name: 'Equipment',
			room_id: 6,
			icon_css_class: 'inventory_icons-Fax_Machine',
		},
		{
			id: 44,
			name: 'Cabinets',
			room_id: 6,
			icon_css_class: 'inventory_icons-Drawer_Lateral_4',
		},
		{
			id: 45,
			name: 'Bookcase',
			room_id: 6,
			icon_css_class: 'inventory_icons-Large_Bookcase',
		},
	],
	[
		{
			id: 57,
			name: 'Tables',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Dining_Table',
		},
		{
			id: 58,
			name: 'Chairs',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Chair',
		},
		{
			id: 59,
			name: 'Sofas',
			room_id: 7,
			icon_css_class: 'inventory_icons-Outdoor_Sofa',
		},
		{
			id: 60,
			name: 'Plants',
			room_id: 7,
			icon_css_class: 'inventory_icons-Medium_Plant',
		},
		{
			id: 61,
			name: 'Grills',
			room_id: 7,
			icon_css_class: 'inventory_icons-Large_Grill',
		},
	],
	[
		{
			id: 46,
			name: 'Instruments',
			room_id: 8,
			icon_css_class: 'inventory_icons-Guitar',
		},
		{
			id: 47,
			name: 'Chairs',
			room_id: 8,
			icon_css_class: 'inventory_icons-Dining_Chair',
		},
		{
			id: 64,
			name: 'Cabinets',
			room_id: 8,
			icon_css_class: 'inventory_icons-Small_China_Cabinet',
		},
		{
			id: 48,
			name: 'Sports & Hobbies',
			room_id: 8,
			icon_css_class: 'inventory_icons-Snowboard',
		},
		{
			id: 49,
			name: 'Home Appliances',
			room_id: 8,
			icon_css_class: 'inventory_icons-Vacuum_Cleaner',
		},
		{
			id: 50,
			name: 'Stereo Equipment',
			room_id: 8,
			icon_css_class: 'inventory_icons-Stereo_or_Video_Component',
		},
		{
			id: 51,
			name: 'Games',
			room_id: 8,
			icon_css_class: 'inventory_icons-Pool_Table',
		},
		{
			id: 52,
			name: 'Odds & Ends',
			room_id: 8,
			icon_css_class: 'inventory_icons-Basket',
		},
		{
			id: 53,
			name: 'Tables',
			room_id: 8,
			icon_css_class: 'inventory_icons-Dining_Table',
		},
		{
			id: 54,
			name: 'Rugs',
			room_id: 8,
			icon_css_class: 'inventory_icons-Large_Rug',
		},
		{
			id: 55,
			name: 'Art & Sculptures',
			room_id: 8,
			icon_css_class: 'inventory_icons-Sculpture',
		},
		{
			id: 56,
			name: 'Travel & Storage',
			room_id: 8,
			icon_css_class: 'inventory_icons-Trunk',
		},
	],
	[
		{
			id: 66,
			name: 'Boxes',
			room_id: 10,
			icon_css_class: 'inventory_icons-Box',
		},
	],
];

export const HOME_SIZES_SELECT = [
	{
		value: 1,
		text: 'Studio',
		subText: '(400-600 sq ft)',
		short_formatted_type: 'A studio',
		src: '/img/homeSize/studio.jpg',
	},
	{
		value: 2,
		text: 'Studio Alcove',
		subText: '(600-700 sq ft)',
		short_formatted_type: 'A studio alcove',
		src: '/img/homeSize/studio_alcove.jpg',
	},
	{
		value: 3,
		text: '1 Bedroom, Small',
		subText: '(600-800 sq ft)',
		short_formatted_type: '1 small bedroom',
		src: '/img/homeSize/1_bedroom.jpg',
	},
	{
		value: 4,
		text: '1 Bedroom, Large',
		subText: '(800-1000 sq ft)',
		short_formatted_type: '1 large bedroom',
		src: '/img/homeSize/1_bedroom_large.PNG',
	},
	{
		value: 5,
		text: '2 Bedroom',
		subText: '(1000 to 1500 sq ft)',
		short_formatted_type: '2 bedrooms',
		src: '/img/homeSize/2_bedroom.jpg',
	},
	{
		value: 6,
		text: '3 Bedroom',
		subText: '(1500 to 2000 sq ft)',
		short_formatted_type: '3 bedrooms',
		src: '/img/homeSize/3_bedroom.jpg',
	},
	{
		value: 7,
		text: '4+ Bedroom',
		subText: 'over 2000 sq ft',
		short_formatted_type: '4+ bedrooms',
		src: '/img/homeSize/4_bedroom.PNG',
	},
	{
		value: 17,
		text: 'One item',
		subText: false,
		short_formatted_type: 'One item',
		src: '/img/homeSize/one_item.PNG',
	},
	{
		value: 16,
		text: 'Just a few items',
		subText: false,
		short_formatted_type: 'Just a few items',
		src: '/img/homeSize/just_few_items.PNG',
	},
];

export const HOME_SIZES = {
	1: {
		type: 'Studio',
		size: '400-600 sq ft',
		short_formatted_type: 'A studio',
	},
	2: {
		type: 'Studio Alcove',
		size: '600-700 sq ft',
		short_formatted_type: 'A studio alcove',
	},
	3: {
		type: '1 Bedroom, Small',
		size: '600-800 sq ft',
		short_formatted_type: '1 small bedroom',
	},
	4: {
		type: '1 Bedroom, Large',
		size: '800-1000 sq ft',
		short_formatted_type: '1 large bedroom',
	},
	5: {
		type: '2 Bedroom',
		size: '1000 to 1500 sq ft',
		short_formatted_type: '2 bedrooms',
	},
	6: {
		type: '3 Bedroom',
		size: '1500 to 2000 sq ft',
		short_formatted_type: '3 bedrooms',
	},
	7: {
		type: '4+ Bedroom',
		size: 'over 2000 sq ft',
	},
	17: {
		type: 'One item',
		size: '',
		short_formatted_type: 'One item',
	},
	16: {
		type: 'Just a few items',
		size: '',
		short_formatted_type: 'Just a few items',
	},
};