import React, {useState} from "react";
import { Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
const TypicalsToggle = props => {

    const [radioValue, setRadioValue] = useState(true)

    const handleSelect = e => {
        e.persist()
        console.log('event', e)
        setRadioValue(Boolean(Number(e.target.value)))
		props.setFieldValue(props.addressKey,Boolean(Number(e.target.value)));
	};
    const mediaqueryMatches = window.matchMedia('(max-width: 560px)').matches
    return(
        <div className="move-date-container inventory-container">
            {
                mediaqueryMatches ? <div className='icon-container' >
            
                <div className="move-date-svg" style={{textAlign: 'center'}}>

                    <svg
                        style={{ fill: '#000', width: '30px' }}
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 22 14">
                        <path d="M20.5,7V5.5h-1.5V4.1h-1.6l0,0h-3.1V0H0v12.4h2.9c0.2,1.1,1.3,1.8,2.3,1.6c0.8-0.2,1.4-0.8,1.6-1.6h8 c0.2,1.1,1.3,1.8,2.3,1.6c0.8-0.2,1.4-0.8,1.6-1.6H22V7H20.5z M10.8,3.8L6.3,9L5.8,9.6L5.2,9.1L3.5,7.6c-0.3-0.3-0.3-0.7-0.1-1 c0,0,0,0,0.1-0.1c0.3-0.3,0.8-0.3,1.1,0l1,0.9l3.9-4.5c0.3-0.3,0.8-0.4,1.1-0.1C11,3,11.1,3.5,10.8,3.8C10.9,3.8,10.8,3.8,10.8,3.8 L10.8,3.8z"/>
                    </svg>

                </div>
                <label className="move-date-label" htmlFor="react-date-picker">
                    Inventory
                </label>
                

            </div>
            :
            <>
            <div className="move-date-svg" style={{textAlign: 'center'}}>
                <svg
                    style={{ fill: '#000', width: '30px' }}
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 22 14">
                    <path d="M20.5,7V5.5h-1.5V4.1h-1.6l0,0h-3.1V0H0v12.4h2.9c0.2,1.1,1.3,1.8,2.3,1.6c0.8-0.2,1.4-0.8,1.6-1.6h8 c0.2,1.1,1.3,1.8,2.3,1.6c0.8-0.2,1.4-0.8,1.6-1.6H22V7H20.5z M10.8,3.8L6.3,9L5.8,9.6L5.2,9.1L3.5,7.6c-0.3-0.3-0.3-0.7-0.1-1 c0,0,0,0,0.1-0.1c0.3-0.3,0.8-0.3,1.1,0l1,0.9l3.9-4.5c0.3-0.3,0.8-0.4,1.1-0.1C11,3,11.1,3.5,10.8,3.8C10.9,3.8,10.8,3.8,10.8,3.8 L10.8,3.8z"/>
                </svg>
            </div>
            <label className="move-date-label" htmlFor="react-date-picker">
            Inventory
            </label>
            </>
                }
			<div className="date-picker-container">
                <label className="prof-packing-container f-s-xs-14 f-w-600 p-t-10 p-r-5" style={{padding:0}}>
                    <div className="prof-packing-radio-container">
                        <input
                            type="radio"
                            value={1}
                            checked={radioValue === true}
                            onChange={handleSelect}
                            // onBlur={props.handlerBlur}
                            className="p-l-5 align-middle"
                            style={{ position: 'relative', top: '-1px' }}
                            name="add_typicals"
                        />
                    </div>
                    <div className="prof-packing-info-container typicals-text" >
                        <span>{"Continue with Typical Inventory (Fast Price)"}</span>
                        <Tooltip
                        enterTouchDelay={200}
                        placement="top"
                        title="We'll add the typical stuffs that are into an space that you select, after you will be able to modify it"
                        >
                            <HelpOutline />
                        </Tooltip>
                        {/* <p className="prof-packing-info-text">
                            Your mover will provide boxes
                        </p> */}
                    </div>
                </label>
                <label className="prof-packing-container f-s-xs-14 f-w-600 p-t-10 p-r-5" style={{padding:0}}>
                    <div className="prof-packing-radio-container">
                        <input
                            type="radio"
                            value={0}
                            checked={radioValue === false}
                            onChange={handleSelect}
                            className="p-l-5 align-middle"
                            style={{ position: 'relative', top: '-1px' }}
                            name="add_typicals"
                            // onBlur={props.handlerBlur}
                        />
                    </div>
                    <div className="prof-packing-info-container">
                        <span>{"Choose my Own Inventory (More Customized Price)"}</span>
                        {/* <p className="prof-packing-info-text">
                            Your mover will provide boxes
                        </p> */}
                    </div>
                </label>
			</div>
			{/* <div className="move-date-edit">
				<EditRoundedIcon />
			</div> */}
		</div>
    )
}

export default TypicalsToggle