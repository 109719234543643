import { NETWORK_SUCCESS, GET_SUGGESTIONS, IS_MOVENOW_EMBED } from '../actionTypes';
import { apiRequest } from '../actions/api';
import { setSuggestions, setMoveNowIsEmbed, setMoverParams } from '../actions/common';

const compareMiddleware =
	({ getState, dispatch }) =>
	next =>
	async action => {
		next(action);
		if (action.type === GET_SUGGESTIONS) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_URL}/mapbox/geocode`,
					method: 'GET',
					params: {
						search: action.payload.query,
						use_up: true,
						exact_address: action.payload.useExact,
					},
					fromAction: {
						type: GET_SUGGESTIONS,
					},
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction.type) {
				case GET_SUGGESTIONS:
					dispatch(setSuggestions(action.payload.response.data));
					break;
				default:
					break;
			}
		}
		if(action.type === IS_MOVENOW_EMBED){
			if(action.payload.isEmbed === true){
				dispatch(setMoveNowIsEmbed(action.payload.isEmbed))
				dispatch(setMoverParams(action.payload.moverParams))
			}
		}
	};

export default compareMiddleware;
