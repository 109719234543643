import {
	BOOK,
	CHECK_PROMO,
	INIT_BOOK_PAGE,
	NETWORK_SUCCESS,
	SUBMIT_BOOK,
	NETWORK_FAILURE,
	CALCULATE_BOOK,
	CALCULATE_BOOK_FOREMBEDS
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	setBookContact,
	setLoginInfo,
	setCardErrors,
	setClientToken,
	calculateBook,
	submitBook,
	setBookNonce,
	setPromoLoading,
	setPaypalComplete,
	setPaypalEmail,
	setBookLoading,
	setLoginFailure,
	checkPromo,
} from '../actions/book';
import { 
	setCurrentMoveplanID, 
	updateMovePlan, 
	setMoveplanLoading,
	getCurrentMoveplan,
	setDnaError
} from '../actions/movePlan';
import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import {
	gotoCongrats,
	setCurrentStep,
	setStepCompleted,
} from '../actions/funnel';
import has from 'lodash-es/has';
import paypalCheckout from 'braintree-web/paypal-checkout';
import { setErrorMessage } from '../actions/auth';
import { openModal, closeAllModals } from '../actions/modals';
import { setCouponData, setInvalidCoupon } from '../actions/analytics';
import { getTotalCost } from '../reducers/book';
import { push } from 'connected-react-router';


const bookMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		const isEmbed = getState().common.isEmbed

		if (action.type === INIT_BOOK_PAGE) {
			dispatch(setBookLoading(true));
			if (getState().movePlan.currentMPID !== action.payload) {
				dispatch(setCurrentMoveplanID(action.payload));
			}
			dispatch(setCurrentStep('BOOK'));
			dispatch(setStepCompleted('REVIEW'));
			dispatch(setBookNonce());
			if (has(getState().analytics.couponData, 'code')) {
				dispatch(checkPromo(getState().analytics.couponData.code));
			}
			if (!!getState().auth.authErrorText) dispatch(setErrorMessage(''));

			// Client_token being generated from braintree (see booking_controller/client_token)
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${
						getState().movePlan.currentMPID
					}/bookings/client_token`,
					method: 'GET',
					fromAction: INIT_BOOK_PAGE,
				})
			);
		}

		if (action.type === CHECK_PROMO) {
			dispatch(setPromoLoading(true));
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${
						getState().movePlan.currentMPID
					}/check_coupon`,
					method: 'GET',
					params: {
						code: action.payload,
					},
					fromAction: CHECK_PROMO,
				})
			);
		}

		if (action.type === BOOK) {
			dispatch(setBookLoading(true));
			// console.log('Pasa por el BOOK')
			const values = action.payload;
			let payment = {
				first_name: values.fname,
				last_name: values.lname 
			};
			dispatch(
				setBookContact({
					contact_information: {
						name: values.name,
						phone_number: values.phoneNumber,
					},
					payment: payment,
				})
			);

			if(!isEmbed){
				if (
					!getState().auth.isEmailTaken &&
					!!values.registrationEmail &&
					!!values.newPassword1 &&
					!!values.newPassword2
				) {
					dispatch(
						setLoginInfo({
							isEmailTaken: getState().auth.isEmailTaken,
							register_info: {
								email: values.registrationEmail,
								password: values.newPassword1,
								password_confirmation: values.newPassword2,
							},
						})
					);
				} else if (!!values.registrationEmail && !!values.newPassword1) {
					dispatch(
						setLoginInfo({
							isEmailTaken: getState().auth.isEmailTaken,
							login_info: {
								email: values.registrationEmail,
								password: values.newPassword1,
							},
						})
					);
				}
			} else {
				if (
					!getState().auth.isEmailTaken &&
					!!values.registrationEmail
				) {
					dispatch(
						setLoginInfo({
							isEmailTaken: getState().auth.isEmailTaken,
							register_info: {
								email: values.registrationEmail,
							},
						})
					);
				} else if (!!values.registrationEmail) {
					dispatch(
						setLoginInfo({
							isEmailTaken: getState().auth.isEmailTaken,
							login_info: {
								email: values.registrationEmail,
							},
						})
					);
				}
			}

			// if (!has(getState().auth, 'user') || getState().auth.user === null) {
			// 	if (
			// 		!!values.registrationEmail &&
			// 		!!values.newPassword1 &&
			// 		!!values.newPassword2
			// 	) {
			// 		dispatch(
			// 			registerUser(
			// 				{
			// 					email: values.registrationEmail,
			// 					password: values.newPassword1,
			// 					password_confirmation: values.newPassword2,
			// 				},
			// 				{ context: 'book' }
			// 			)
			// 		);
			// 	}
			// }
			sessionStorage.setItem(
				'contact_information',
				JSON.stringify({ name: values.name, phone_number: values.phoneNumber })
			);
			if (values.user_note) {
				dispatch(updateMovePlan({ user_note: values.user_note }));
			}

			if(!isEmbed){
				if (
					has(getState().auth, 'user' && getState().auth.user !== null) &&
					has(getState().book.bookPayload.payment, 'billing_nonce') &&
					!!getState().book.bookPayload.payment.billing_nonce
				) {
					// PAYPAL
					// dispatch(openModal({ name: 'loader', properties: { state: 'book' }}));
					dispatch(calculateBook());
				} else {
					// BRAINTREE
					console.log('Braintree else')
					let bookForm = document.getElementById('book-form');
					const submitBookEvent = new CustomEvent('runBraintreeAuth', {
						bubbles: true,
					});
					bookForm.dispatchEvent(submitBookEvent);
				}
			} else {
				dispatch(calculateBook())
			}
		}

		if (action.type === CALCULATE_BOOK) {
			const baseValues = {
				url: `api/v3/move_plans/${
					getState().movePlan.currentMPID
				}/bookings/calculate_for_move_now`,
				method: 'POST',
				fromAction: CALCULATE_BOOK,
			}
			dispatch(
				apiRequest(baseValues)
			);
		}
		if(action.type === CALCULATE_BOOK_FOREMBEDS) {
			const baseValues = {
				url: `api/v3/move_plans/${
					getState().movePlan.currentMPID
				}/bookings/calculate_for_move_now_by_mover`,
				params:{
					mover_id: getState().common.embedMoverId
				},
				method: 'POST',
				fromAction: CALCULATE_BOOK_FOREMBEDS,
			}
			dispatch(
				apiRequest(baseValues)
			);

		}

		if (action.type === SUBMIT_BOOK) {
			let payload = getState().book.bookPayload;
			let payload2 = getState();
			console.log('payload del submit', payload)
			console.log('payload2 del submit', payload2)
			const infoToSend = isEmbed ?
				{
					register_info:{
						name: payload.contact_information.name,
						phone_number: payload.contact_information.phone_number,
						email: payload.login_info ? payload.login_info.email : payload.register_info.email
					}
				}
			: payload
			
			if (
				has(getState().analytics.couponData, 'coupon') &&
				getState().analytics.couponData.coupon.success
			) {
				payload.payment.coupon = getState().analytics.couponData.coupon.code;
			}

			dispatch(apiRequest({
				url: `api/v3/move_plans/${
					getState().movePlan.currentMPID
				}/bookings/create_for_move_now`,
				method: 'POST',
				params: infoToSend,
				fromAction: SUBMIT_BOOK,
			}));
		}

		if (action.type === NETWORK_SUCCESS) {
			console.log('Pasa por el network sucess')
			let resp;
			switch (action.meta.originalRequest.fromAction) {
				case CHECK_PROMO:
					if (action.payload.response.data.coupon.success) {
						dispatch(setCouponData(action.payload.response.data));
						dispatch(setInvalidCoupon(false));
						dispatch(
							updateMovePlan({
								promo_code: action.payload.response.data.coupon.code,
							})
						);
					} else {
						dispatch(setInvalidCoupon(true));
					}
					dispatch(setPromoLoading(false));
					break;

				case INIT_BOOK_PAGE:					
					if(!isEmbed){
						dispatch(
							setClientToken(action.payload.response.data.braintree_client_token)
						);
						client.create(
							{
								authorization:
									action.payload.response.data.braintree_client_token,
							},
							function (err, clientInstance) {
								if (err) {
									console.error('Error creating braintree client', err);
									return;
								}
								
								hostedFields.create(
									{
										client: clientInstance,
										styles: {
											input: {
												color: '#042b3b',
											},
											'.valid': {
												color: 'green',
											},
											'.invalid': {
												color: 'red',
												'border-color': 'red',
											},
										},
										fields: {
											number: {
												selector: '#card-number',
												placeholder: 'Card Number',
											},
											cvv: {
												selector: '#cvv',
												placeholder: 'CVV/CVC',
											},
											expirationMonth: {
												selector: '#expiration-month',
												placeholder: 'MM',
											},
											expirationYear: {
												selector: '#expiration-year',
												placeholder: 'YY',
											},
											postalCode: {
												selector: '#postal-code',
												placeholder: 'Billing Zip',
											},
										},
									},
									function (err, hostedFieldsInstance) {
										if (err) {
											console.error(err);
											return;
										}
										// dispatch(setHostedFieldsInstance(hostedFieldsInstance));
										hostedFieldsInstance.on('focus', function (event) {
											dispatch(setCardErrors({ [event.emittedBy]: false }));
										});
										hostedFieldsInstance.on('blur', function (event) {
											let field = event.fields[event.emittedBy];
											let errors = {};
											switch (event.emittedBy) {
												case 'number':
													if (!field.isValid)
														errors.number = 'Invalid card number';
													else errors.number = false;
													dispatch(setCardErrors(errors));
													return -1;
												case 'expirationMonth':
													if (!field.isValid)
														errors.expirationMonth = 'Invalid month';
													else errors.expirationMonth = false;
													dispatch(setCardErrors(errors));
													return -1;
												case 'expirationYear':
													if (!field.isValid)
														errors.expirationYear = 'Invalid year';
													else errors.expirationYear = false;
													dispatch(setCardErrors(errors));
													return -1;
												case 'cvv':
													if (!field.isValid) errors.cvv = 'Invalid code';
													else errors.cvv = false;
													dispatch(setCardErrors(errors));
													return -1;
												case 'postalCode':
													if (!field.isValid)
														errors.postalCode = 'Invalid Postal Code';
													else errors.postalCode = false;
													dispatch(setCardErrors(errors));
													return -1;
												default:
													dispatch(setCardErrors({}));
													break;
											}
										});
										console.log('Va a declarar el addEventListener')
										let elem = document.getElementById('book-form');
										elem.addEventListener('runBraintreeAuth', function () {
											//check for paypal or splitit payment
											console.log('Entra a al eventListener de brainTree')
											if (
												has(getState().book.bookPayload, 'payment') &&
												!!getState().book.bookPayload.payment.billing_nonce &&
												!getState().book.isLoginFailure
											) {
												return;
											}
											// Check that the form is valid
											let state = hostedFieldsInstance.getState();
											let isFormValid = Object.keys(state.fields).every(
												key => state.fields[key].isValid
											);
											if (isFormValid) {
												hostedFieldsInstance.tokenize(function (err, payload) {
													if (err) {
														console.error(err);
														dispatch(setCardErrors({ err }));
														return;
													} else if (!!payload) {
														dispatch(setBookNonce(payload.nonce));
														dispatch(calculateBook());

														// if (
														// 	has(getState().auth, 'user') &&
														// 	getState().auth.user !== null
														// ) {
														// 	dispatch(calculateBook());
														// }
													}
													return;
												});
											} else {
												let errors = {};
												Object.keys(state.fields).map(function (key) {
													if (!state.fields.isValid) errors[key] = 'Required';
													return state.fields[key].isValid;
												});
												dispatch(closeAllModals());
												dispatch(setCardErrors(errors));
												dispatch(setBookLoading(false));
											}
										});
									}
								);

								paypalCheckout.create(
									{
										client: clientInstance,
									},
									function (paypalCheckoutErr, paypalCheckoutInstance) {
										if (paypalCheckoutErr) {
											console.error(
												'Error creating PayPal Checkout:',
												paypalCheckoutErr
											);
											return;
										}
										const getPaypalScript = () => {
											return new Promise((resolve, reject) => {
												let script = document.createElement('script');
												script.type = 'text/javascript';
												script.src = `https://www.paypal.com/sdk/js?client-id=${
													process.env.REACT_APP_PAYPAL_ID
												}&vault=true${
													process.env.REACT_APP_ENV === 'prod'
														? ''
														: '&debug=true'
												}`;
												script.addEventListener(
													'load',
													() => resolve(script),
													false
												);
												script.addEventListener(
													'error',
													() => reject(script),
													false
												);
												document.body.appendChild(script);
											});
										};
										const initPaypal = async () => {
											//eslint-disable-next-line
											let t = await getPaypalScript();
											return window.paypal
												.Buttons({
													env:
														process.env.REACT_APP_ENV === 'prod'
															? 'production'
															: 'sandbox',
													style: {
														color: 'blue',
														layout: 'horizontal',
														tagline: 'false',
													},
													createBillingAgreement: function () {
														return paypalCheckoutInstance.createPayment({
															flow: 'vault',
															amount: getTotalCost(
																getState().movePlan,
																getState().analytics
															), // Required.
															currency: 'USD', // Required
														});
													},

													onApprove: function (data, actions) {
														// some logic here before tokenization happens below
														return paypalCheckoutInstance
															.tokenizePayment(data)
															.then(function (payload) {
																if (payload) {
																	dispatch(setPaypalEmail(payload.details.email));
																	dispatch(setBookNonce(payload.nonce));
																	dispatch(setPaypalComplete(true));
																}
															});
													},
													onCancel: function () {
														dispatch(setBookNonce());
													},
													onError: function (err) {
														// handle case where error occurs
														console.log(err);
													},
												})
												.render('#paypal-button');
										};
											initPaypal();
									}
								);
							}
						);
					} else {
						let form = document.getElementById('book-form');
						form.addEventListener('submit', () =>{
							dispatch(calculateBook());
						})

					}
					dispatch(setBookLoading(false));
					break;
				case CALCULATE_BOOK:
					dispatch(submitBook());
					break;
				case CALCULATE_BOOK_FOREMBEDS:
					console.log('Action del Calculate', action.payload)
					resp = action.payload.response.data;

					// dispatch(getMoveplan())
					if (resp.move_plan_cached) {
						dispatch(setMoveplanLoading(false));
						// dispatch(
						// 	push(`/moving/${action.payload.response.data.uuid}/review`)
						// );
						dispatch(
						push(`/moving/${getState().movePlan.currentMPID}/review`)
						);
					} else {
						dispatch(setCurrentMoveplanID(action.payload.response.data.uuid));
						dispatch(getCurrentMoveplan());
						dispatch(setDnaError(resp));
					}


				break;
				case SUBMIT_BOOK:
					let plan = getState().movePlan.currentPlan;
					let name, sku, suffix;
					if (plan.details.move_into_storage) {
						name = 'Move Into Storage';
						sku = 'moving-to-storage';
						suffix = 'mis';
					} else if (plan.details.storage_in_transit) {
						name = 'Move with SIT';
						sku = 'moving-sit';
						suffix = 'sit';
					} else {
						name = 'Move job';
						sku = '';
						suffix = 'move';
					}
					const coupon = has(getState().analytics.couponData, 'coupon')
						? getState().analytics.couponData.coupon.code
						: '';
					// Google Adwords and facbook pixel
					if (!!window.analytics) {
						// this tracks to google adwords
						window.analytics.track('Order Completed', {
							checkout_id: plan.uuid,
							order_id: plan.uuid,
							affiliation: 'Flatrate Funnel',
							total: plan.pricing.total_cost,
							revenue: plan.pricing.total_cost,
							shipping: 0,
							tax: 0,
							coupon: coupon,
							currency: 'USD',
							products: [
								{
									product_id: `${plan.uuid}-${suffix}`,
									sku: sku,
									name: name,
									price: plan.pricing.total_cost,
									quantity: 1,
								},
							],
						});
					}
					dispatch(setBookLoading(false));
					dispatch(gotoCongrats());
					break;
				default:
					break;
			}
		}

		if (action.type === NETWORK_FAILURE) {
			switch (action.meta.originalRequest.fromAction) {
				case SUBMIT_BOOK:
					const errorResponse =
						action.payload.response.response.request.response;
					const searchText = 'Email or password not matching';
					let isLoginFailure = errorResponse.includes(searchText);
					if (
						action.payload.response.response.status === 422 &&
						!isLoginFailure
					) {
						dispatch(closeAllModals());
						dispatch(
							openModal({
								name: 'apiError',
								properties: {
									error:
										'There was a problem processing this transaction. Please try again, or use another payment method.',
									cardError: true,
								},
							})
						);
						dispatch(setBookLoading(false));
					} else if (isLoginFailure) {
						dispatch(setLoginFailure(true));
						dispatch(setBookLoading(false));
					}
					break;
				case CALCULATE_BOOK_FOREMBEDS:
					console.log('fallo el Calculate', action.payload)
				break;
				default:
					break;
			}
		}
	};

export default bookMiddleware;
