import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions/auth';
// import { Link } from 'react-router-dom';
import has from 'lodash-es/has';
import { push } from 'connected-react-router';

const mapStateToProps = ({ auth, common, funnel }) => ({
	isLoggedIn: has(auth, 'user') && auth.user,
	currentUser: has(auth, 'user') ? auth.user : {},
  embedMoverSchemeColor:common.embedMoverSchemeColor,
  isEmbed: common.isEmbed,
  currentStep: funnel.currentStep
});

const Header = props => {
	const [
		showMenu,
		// setShowMenu
	] = useState(false);
	// const toggleMobileMenu = () => setShowMenu(!showMenu)
	// const menuItemClick = () => setShowMenu(false)
	// const goToHomePage = () => {
	//   props.push('/')
	// }
	// const signOut = () => {
	//   setShowMenu(!showMenu);
	//   props.signOut();
	// }
	return (
		<div 
      className={showMenu ? 'funnel-sidebar-open mobile-menu-open' : ''}
      style={props.isEmbed && props.currentStep === 'INVENTORY' ? {height: '70px'} : undefined}
    >
			<section
				className={`header-container ${
					props.changeTheme && 'header-container-themegreen'
				}`}
        style={
          props.embedMoverSchemeColor ? {backgroundColor: props.embedMoverSchemeColor} : undefined
        }
        >
				{/* <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
        </div>
      <span onClick={goToHomePage} className="app-name">Move<b>now</b></span>
      
        <span className="app-name">Move<b>now</b></span>
        <span className="powered-by-logo" /> 
        <nav className="mobile-navbar">
          <div className="menu__level menu__level--current">
            <div className="menu__wrap">
              {props.isLoggedIn &&
                <div className="mobile-users-section" ng-if="isLoggedIn()">
                  <div className="mobile-user-name-holder">
                    <span className="mobile-user-name">{!!props.currentUser.name ? props.currentUser.name : props.currentUser.email}</span>
                  </div>
                  <ul className="mobile-menu mobile-user-menu">
                    <li className="mobile-menu-item" onClick={menuItemClick}>
                      <a href={ process.env. + '/my-home'} className="mobile-menu-item-link" target="_self">My moves</a>
                    </li>
                    <li className="mobile-menu-item" onClick={menuItemClick} ng-if="isAdmin()">
                      <a href={ process.env.REACT_APP_BASE_API_URL + '/admin'} className="mobile-menu-item-link" target="_self">Admin Dashboard</a>
                    </li>
                    <li className="mobile-menu-item" onClick={() => menuItemClick()}>
                      <a href='/my-home' className="mobile-menu-item-link" target="_self">My Moves</a>
                    </li>
                  </ul>
                </div>
              }
              <ul className="mobile-menu mobile-main-menu">
                <li className="mobile-menu-item active">
                  <a className="mobile-menu-item-link active" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL}>Find a moving company</a>
                </li>

                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={process.env.REACT_APP_BASE_URL + '/about'}> About Us</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={process.env.REACT_APP_BASE_URL + '/how-it-works'}>How It Works</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={process.env.REACT_APP_BASE_URL + '/customer-service'}> Customer Support</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/service-providers'}> For Service Providers</a>
                </li>
                {props.isLoggedIn &&
                  <li className="mobile-menu-item">
                    <span className="mobile-menu-item-link" onClick={signOut} >
                      Log out
                    </span>
                  </li>
                }
              </ul>
              {!props.isLoggedIn &&
                <ul className="mobile-menu mobile-actions-menu clearfix" ng-if="!isLoggedIn()">
                  <li className="mobile-action-menu-item d-inline-block pull-left ">
                    <Link to="/register" className="mobile-cta-button" onClick={toggleMobileMenu}>Sign Up</Link>
                  </li>
                  <li className="mobile-action-menu-item d-inline-block pull-right">
                    <Link to="/sign-in" className="mobile-cta-button" onClick={toggleMobileMenu}>Login</Link>
                  </li>
                </ul>
              }
            </div>
          </div>
        </nav>*/}
			</section>
			{/* <div className="dimmer" onClick={toggleMobileMenu}/> */}
		</div>
	);
};

export default connect(mapStateToProps, { signOut, push })(Header);
