import React, { useState } from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { HOME_SIZES_SELECT } from '../../../../constants';
import { setHomeSizeSelected } from '../../../../redux/actions/common';

const TouchableHomeSizeCarousel = props => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [carouselClicked, setCarouselClicked] = useState(false);

	const handleSelect = (selectedIndex, e) => {
		setActiveIndex(selectedIndex);
		props.setFieldValue(
			props.addressKey,
			HOME_SIZES_SELECT[selectedIndex].value
		);
		setCarouselClicked(false);
	};
	const onCarouselClicked = () => {
		setCarouselClicked(!carouselClicked);
		if (!props.homeSizeSelected) {
			setTimeout(() => {
				props.setHomeSizeSelected(true);
			}, 320);
		}
	};
	const slides = HOME_SIZES_SELECT.map(option => {
		return (
			<Carousel.Item
				className={`home-size-carouselItem ${
					carouselClicked && 'carousel-clicked'
				}`}
				key={option.value}
				onClick={() => onCarouselClicked()}>
				<div className="carousel-img-container">
					<img 
						src={option.src} 
						alt={option.type} 
						className="carousel-image"
						style={props.schemeColor ? {filter: 'grayscale(1)'} : undefined} 
					/>
				</div>
				<Carousel.Caption 
					className="carousel-captions"
				>
					<h3
					style={props.schemeColor ? {color: props.schemeColor} : undefined} 
					>{option.text}</h3>
					<p
					style={props.schemeColor ? {color: props.schemeColor} : undefined} 
					>{option.subText}</p>
				</Carousel.Caption>
			</Carousel.Item>
		);
	});
	// next prev btn is included
	return (
		<div
			className={`home-size-container ${
				props.homeSizeSelected && 'home-size-container-selected-homesize'
			}`}>
			<div className="home-size-title-container">
				<p>Select home size</p>
			</div>
			<Carousel
				className="carousel-container"
				activeIndex={activeIndex}
				onSelect={handleSelect}
				interval={10000000}
				indicators={false}>
				{slides}
			</Carousel>
		</div>
	);
};

export default connect(null, { setHomeSizeSelected })(
	TouchableHomeSizeCarousel
);
